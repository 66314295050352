var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-box',[_c('c-flex',{attrs:{"d":['none', 'flex'],"w":"100%","px":"0","py":"0"}},[_c('c-box',{attrs:{"width":"72px","height":"72px","marginRight":"30px"}},[_c('c-box',{attrs:{"w":"72px","h":"72px","overflow":"hidden","borderRadius":"100%","backgroundColor":"lightGray.900"}},[_c('c-image',{attrs:{"w":"72px","h":"72px","object-fit":"cover","src":_vm.getPhotoUser(_vm.client.photoUrl),"alt":_vm.client.firstName}})],1)],1),_c('c-box',{attrs:{"w":"100%"}},[_c('c-heading',{attrs:{"as":"h3","pos":"relative","marginBottom":"10px","fontSize":"18px","lineHeight":"27px","fontWeight":"700","color":"black.900"}},[_vm._v(" "+_vm._s(_vm.client.firstName)+" "+_vm._s(_vm.client.lastName)+" ")]),_c('c-text',{attrs:{"fontFamily":"Roboto","fontSize":"14px","color":"brand.900","marginBottom":"20px"}},[_vm._v(" "+_vm._s(_vm.client.id)+" ")])],1)],1),_c('c-list',{directives:[{name:"chakra",rawName:"v-chakra",value:({
      li: {
        a: {
          color: 'darkGray.900',
          fontSize: '16px',
          lineHeight: '24px',
          py: '20px',
          px: '20px',
          display: 'flex',
          fontWeight: '700',
          borderRadius: [0, '8px'],
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'superLightGray.900',
          },
          '&:focus': {
            outline: 'none',
            boxShadow: 'none',
          },
          '&.router-link-exact-active': {
            backgroundColor: 'superLightGray.900',
            borderLeft: [null, '6px solid #008C81'],
            color: 'brand.900',
            pl: '13px',
            img: {
              path: {
                fill: '#008C81 !important',
              },
            },
          },
        },
        '&.router-link-exact-active': {
          a: {
            backgroundColor: 'superLightGray.900',
            borderLeft: [null, '6px solid #008C81'],
            color: 'brand.900',
            img: {
              path: {
                fill: '#008C81 !important',
              },
            },
          },
        },
      },
    }),expression:"{\n      li: {\n        a: {\n          color: 'darkGray.900',\n          fontSize: '16px',\n          lineHeight: '24px',\n          py: '20px',\n          px: '20px',\n          display: 'flex',\n          fontWeight: '700',\n          borderRadius: [0, '8px'],\n          '&:hover': {\n            textDecoration: 'none',\n            backgroundColor: 'superLightGray.900',\n          },\n          '&:focus': {\n            outline: 'none',\n            boxShadow: 'none',\n          },\n          '&.router-link-exact-active': {\n            backgroundColor: 'superLightGray.900',\n            borderLeft: [null, '6px solid #008C81'],\n            color: 'brand.900',\n            pl: '13px',\n            img: {\n              path: {\n                fill: '#008C81 !important',\n              },\n            },\n          },\n        },\n        '&.router-link-exact-active': {\n          a: {\n            backgroundColor: 'superLightGray.900',\n            borderLeft: [null, '6px solid #008C81'],\n            color: 'brand.900',\n            img: {\n              path: {\n                fill: '#008C81 !important',\n              },\n            },\n          },\n        },\n      },\n    }"}],staticClass:"x-list",attrs:{"d":['flex', 'inherit'],"pr":[null, '27px'],"mt":[null, '20px'],"bg":"white"}},[(!_vm.isMealPlanNav)?_c('c-list-item',{attrs:{"mt":[null, '10px'],"flex-grow":[1, null],"justifyContent":['center', null]}},[_c('c-link',{attrs:{"d":['flex', 'inherit'],"justifyContent":['center', 'flex-start'],"as":"router-link","to":{ name: 'nutri.detail-client' }}},[_c('c-image',{attrs:{"src":_vm.$route.name.includes('nutri.detail-client')
              ? require('@/assets/ic_user_active.svg')
              : require('@/assets/ic_user.svg'),"alt":"icon","display":"inline-block","verticalAlign":"middle","mr":[null, '20px']}}),_c('c-box',{attrs:{"as":"span","d":['none', 'block']}},[_vm._v("Data Diet Klien")])],1)],1):_vm._e(),(!_vm.isMealPlanNav)?_c('c-list-item',{attrs:{"mt":[null, '10px'],"flex-grow":[1, null],"justifyContent":['center', null]}},[_c('c-link',{class:{
          'router-link-exact-active':
            _vm.$route.name.includes('nutri.adime-note'),
        },attrs:{"d":['flex', 'inherit'],"justifyContent":['center', 'flex-start'],"as":"router-link","to":{ name: 'nutri.adime-note' }}},[_c('c-image',{attrs:{"src":_vm.$route.name.includes('nutri.adime-note')
              ? require('@/assets/ic_notebook_active.svg')
              : require('@/assets/ic_notebook.svg'),"alt":"icon","display":"inline-block","verticalAlign":"middle","mr":[null, '20px']}}),_c('c-box',{attrs:{"as":"span","d":['none', 'block']}},[_vm._v("ADIME Note")])],1)],1):_vm._e(),(!_vm.isMealPlanNav)?_c('c-list-item',{attrs:{"mt":[null, '10px'],"flex-grow":[1, null],"justifyContent":['center', null]}},[_c('c-link',{class:{
          'router-link-exact-active': _vm.$route.name.includes(
            'nutri.follow-up-notes'
          ),
        },attrs:{"d":['flex', 'inherit'],"justifyContent":['center', 'flex-start'],"as":"router-link","to":{ name: 'nutri.follow-up-notes' }}},[_c('c-image',{attrs:{"src":_vm.$route.name.includes('nutri.follow-up-notes')
              ? require('@/assets/ic_notes_active.svg')
              : require('@/assets/ic_notes.svg'),"w":"24px","h":"24px","alt":"icon","display":"inline-block","verticalAlign":"middle","mr":[null, '20px']}}),_c('c-box',{attrs:{"as":"span","d":['none', 'block']}},[_vm._v("Follow Up Notes")])],1)],1):_vm._e(),(!_vm.isMealPlanNav)?_c('c-list-item',{attrs:{"mt":[null, '10px'],"flex-grow":[1, null],"justifyContent":['center', null]}},[_c('c-link',{class:{
          'router-link-exact-active': _vm.$route.name.includes(
            'nutri.progress-tracker'
          ),
        },attrs:{"d":['flex', 'inherit'],"justifyContent":['center', 'flex-start'],"as":"router-link","to":{ name: 'nutri.progress-tracker' }}},[_c('c-image',{attrs:{"src":_vm.$route.name.includes('nutri.progress-tracker')
              ? require('@/assets/ic_trending_active.svg')
              : require('@/assets/ic_trending.svg'),"w":"24px","h":"24px","alt":"icon","display":"inline-block","verticalAlign":"middle","mr":[null, '20px']}}),_c('c-box',{attrs:{"as":"span","d":['none', 'block']}},[_vm._v("Progress & Tracker")])],1)],1):_vm._e(),(_vm.isMealPlanNav)?_c('c-list-item',{attrs:{"mt":[null, '10px'],"flex-grow":[1, null],"justifyContent":['center', null]}},[_c('c-link',{attrs:{"d":['flex', 'inherit'],"justifyContent":['center', 'flex-start'],"as":"router-link","to":{ name: 'nutri.profile-gizi' }}},[_c('c-image',{attrs:{"src":require('@/assets/icon-clipboard-dark-grey.svg'),"alt":"icon","d":"inline-block","mr":[null, '20px']}}),_c('c-box',{attrs:{"as":"span","d":['none', 'block']}},[_vm._v("Profile Gizi")])],1)],1):_vm._e(),(_vm.isMealPlanNav)?_c('c-list-item',{attrs:{"mt":[null, '10px'],"flex-grow":[1, null],"justifyContent":['center', null]}},[_c('c-link',{attrs:{"d":['flex', 'inherit'],"justifyContent":['center', 'flex-start'],"as":"router-link","to":{ name: 'nutri.meal-plan' }}},[_c('c-image',{attrs:{"src":require('@/assets/icon-meat-dark-grey.svg'),"alt":"icon","display":"inline-block","verticalAlign":"middle","mr":[null, '20px']}}),_c('c-box',{attrs:{"as":"span","d":['none', 'block']}},[_vm._v("Rencana Makan")])],1)],1):_vm._e(),(_vm.isMealPlanNav)?_c('c-list-item',{attrs:{"mt":[null, '10px'],"flex-grow":[1, null],"justifyContent":['center', null]}},[_c('c-link',{attrs:{"d":['flex', 'inherit'],"justifyContent":['center', 'flex-start'],"as":"router-link","to":{ name: 'nutri.rekomendasi-menu' }}},[_c('c-image',{attrs:{"src":require('@/assets/icon-calendar-dark-grey.svg'),"alt":"icon","d":"inline-block","mr":[null, '20px']}}),_c('c-box',{attrs:{"as":"span","d":['none', 'block']}},[_vm._v("Rekomendasi Menu")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }