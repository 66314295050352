<template>
  <c-box>
    <c-flex :d="['none', 'flex']" w="100%" px="0" py="0">
      <c-box width="72px" height="72px" marginRight="30px">
        <c-box
          w="72px"
          h="72px"
          overflow="hidden"
          borderRadius="100%"
          backgroundColor="lightGray.900"
        >
          <c-image
            w="72px"
            h="72px"
            object-fit="cover"
            :src="getPhotoUser(client.photoUrl)"
            :alt="client.firstName"
          />
        </c-box>
      </c-box>
      <c-box w="100%">
        <c-heading
          as="h3"
          pos="relative"
          marginBottom="10px"
          fontSize="18px"
          lineHeight="27px"
          fontWeight="700"
          color="black.900"
        >
          {{ client.firstName }} {{ client.lastName }}
        </c-heading>
        <c-text
          fontFamily="Roboto"
          fontSize="14px"
          color="brand.900"
          marginBottom="20px"
        >
          {{ client.id }}
        </c-text>
      </c-box>
    </c-flex>
    <c-list
      :d="['flex', 'inherit']"
      :pr="[null, '27px']"
      :mt="[null, '20px']"
      bg="white"
      class="x-list"
      v-chakra="{
        li: {
          a: {
            color: 'darkGray.900',
            fontSize: '16px',
            lineHeight: '24px',
            py: '20px',
            px: '20px',
            display: 'flex',
            fontWeight: '700',
            borderRadius: [0, '8px'],
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: 'superLightGray.900',
            },
            '&:focus': {
              outline: 'none',
              boxShadow: 'none',
            },
            '&.router-link-exact-active': {
              backgroundColor: 'superLightGray.900',
              borderLeft: [null, '6px solid #008C81'],
              color: 'brand.900',
              pl: '13px',
              img: {
                path: {
                  fill: '#008C81 !important',
                },
              },
            },
          },
          '&.router-link-exact-active': {
            a: {
              backgroundColor: 'superLightGray.900',
              borderLeft: [null, '6px solid #008C81'],
              color: 'brand.900',
              img: {
                path: {
                  fill: '#008C81 !important',
                },
              },
            },
          },
        },
      }"
    >
      <c-list-item
        v-if="!isMealPlanNav"
        :mt="[null, '10px']"
        :flex-grow="[1, null]"
        :justifyContent="['center', null]"
      >
        <c-link
          :d="['flex', 'inherit']"
          :justifyContent="['center', 'flex-start']"
          as="router-link"
          :to="{ name: 'nutri.detail-client' }"
        >
          <c-image
            :src="
              $route.name.includes('nutri.detail-client')
                ? require('@/assets/ic_user_active.svg')
                : require('@/assets/ic_user.svg')
            "
            alt="icon"
            display="inline-block"
            verticalAlign="middle"
            :mr="[null, '20px']"
          />
          <c-box as="span" :d="['none', 'block']">Data Diet Klien</c-box>
        </c-link>
      </c-list-item>
      <c-list-item
        v-if="!isMealPlanNav"
        :mt="[null, '10px']"
        :flex-grow="[1, null]"
        :justifyContent="['center', null]"
      >
        <c-link
          :d="['flex', 'inherit']"
          :justifyContent="['center', 'flex-start']"
          as="router-link"
          :to="{ name: 'nutri.adime-note' }"
          :class="{
            'router-link-exact-active':
              $route.name.includes('nutri.adime-note'),
          }"
        >
          <c-image
            :src="
              $route.name.includes('nutri.adime-note')
                ? require('@/assets/ic_notebook_active.svg')
                : require('@/assets/ic_notebook.svg')
            "
            alt="icon"
            display="inline-block"
            verticalAlign="middle"
            :mr="[null, '20px']"
          />
          <c-box as="span" :d="['none', 'block']">ADIME Note</c-box>
        </c-link>
      </c-list-item>
      <c-list-item
        v-if="!isMealPlanNav"
        :mt="[null, '10px']"
        :flex-grow="[1, null]"
        :justifyContent="['center', null]"
      >
        <c-link
          :d="['flex', 'inherit']"
          :justifyContent="['center', 'flex-start']"
          as="router-link"
          :to="{ name: 'nutri.follow-up-notes' }"
          :class="{
            'router-link-exact-active': $route.name.includes(
              'nutri.follow-up-notes'
            ),
          }"
        >
          <c-image
            :src="
              $route.name.includes('nutri.follow-up-notes')
                ? require('@/assets/ic_notes_active.svg')
                : require('@/assets/ic_notes.svg')
            "
            w="24px"
            h="24px"
            alt="icon"
            display="inline-block"
            verticalAlign="middle"
            :mr="[null, '20px']"
          />
          <c-box as="span" :d="['none', 'block']">Follow Up Notes</c-box>
        </c-link>
      </c-list-item>
      <c-list-item
        v-if="!isMealPlanNav"
        :mt="[null, '10px']"
        :flex-grow="[1, null]"
        :justifyContent="['center', null]"
      >
        <c-link
          :d="['flex', 'inherit']"
          :justifyContent="['center', 'flex-start']"
          as="router-link"
          :to="{ name: 'nutri.progress-tracker' }"
          :class="{
            'router-link-exact-active': $route.name.includes(
              'nutri.progress-tracker'
            ),
          }"
        >
          <c-image
            :src="
              $route.name.includes('nutri.progress-tracker')
                ? require('@/assets/ic_trending_active.svg')
                : require('@/assets/ic_trending.svg')
            "
            w="24px"
            h="24px"
            alt="icon"
            display="inline-block"
            verticalAlign="middle"
            :mr="[null, '20px']"
          />
          <c-box as="span" :d="['none', 'block']">Progress &amp; Tracker</c-box>
        </c-link>
      </c-list-item>
      <c-list-item
        v-if="isMealPlanNav"
        :mt="[null, '10px']"
        :flex-grow="[1, null]"
        :justifyContent="['center', null]"
      >
        <c-link
          :d="['flex', 'inherit']"
          :justifyContent="['center', 'flex-start']"
          as="router-link"
          :to="{ name: 'nutri.profile-gizi' }"
        >
          <c-image
            :src="require('@/assets/icon-clipboard-dark-grey.svg')"
            alt="icon"
            d="inline-block"
            :mr="[null, '20px']"
          />
          <c-box as="span" :d="['none', 'block']">Profile Gizi</c-box>
        </c-link>
      </c-list-item>
      <c-list-item
        v-if="isMealPlanNav"
        :mt="[null, '10px']"
        :flex-grow="[1, null]"
        :justifyContent="['center', null]"
      >
        <c-link
          :d="['flex', 'inherit']"
          :justifyContent="['center', 'flex-start']"
          as="router-link"
          :to="{ name: 'nutri.meal-plan' }"
        >
          <c-image
            :src="require('@/assets/icon-meat-dark-grey.svg')"
            alt="icon"
            display="inline-block"
            verticalAlign="middle"
            :mr="[null, '20px']"
          />
          <c-box as="span" :d="['none', 'block']">Rencana Makan</c-box>
        </c-link>
      </c-list-item>
      <c-list-item
        v-if="isMealPlanNav"
        :mt="[null, '10px']"
        :flex-grow="[1, null]"
        :justifyContent="['center', null]"
      >
        <c-link
          :d="['flex', 'inherit']"
          :justifyContent="['center', 'flex-start']"
          as="router-link"
          :to="{ name: 'nutri.rekomendasi-menu' }"
        >
          <c-image
            :src="require('@/assets/icon-calendar-dark-grey.svg')"
            alt="icon"
            d="inline-block"
            :mr="[null, '20px']"
          />
          <c-box as="span" :d="['none', 'block']">Rekomendasi Menu</c-box>
        </c-link>
      </c-list-item>
    </c-list>
  </c-box>
</template>

<script>
import { mapActions } from "vuex"
import generalMixin from "@/utils/general-mixins"

export default {
  name: "NutritionistNavigation",
  mixins: [generalMixin],
  computed: {
    clientId() {
      return this.$route.params.clientId
    },
    client() {
      return this.$store.getters["clients/clientWithId"](this.clientId) ?? {}
    },
    isMealPlanNav() {
      return this.$route.matched
        .map((it) => it.meta.mealPlanNav)
        .some((it) => it)
    },
  },
  methods: {
    ...mapActions({
      getDetail: "clients/getClientById",
    }),
  },
  watch: {
    clientId: {
      immediate: true,
      handler(clientId) {
        this.getDetail(clientId)
      },
    },
  },
}
</script>

<style></style>
