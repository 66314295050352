<template>
  <c-breadcrumb :display="['none', 'inherit']">
    <c-breadcrumb-item
      v-for="item in items"
      :key="item.label"
      :isCurrentPage="item.isCurrent"
      font-size="14px"
    >
      <c-breadcrumb-link
        class="breadcrumb-link"
        :href="item.href"
        :is-current-page="item.isCurrent"
        :color="!item.isCurrent ? ['brand.900'] : []"
        font-size="14px"
      >
        {{ item.label }}
      </c-breadcrumb-link>
    </c-breadcrumb-item>
  </c-breadcrumb>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["items"],
}
</script>

<style scoped>
::v-deep .breadcrumb-link {
  font-size: 14px;
}
</style>
