<template>
  <c-box>
    <Header :logo="'hide'" back="true" :back-title="backTitle" />
    <c-box paddingTop="30px" paddingBottom="80px" max-w="1200px" mx="auto">
      <Breadcrumb :items="breadcrumbs" />
      <hr :d="['none', 'flex']" v-chakra mt="10px" />
      <c-flex :mt="[null, '30px']">
        <c-box
          :flex-basis="['100%', '30%']"
          flex-shrink="0"
          border-right="1px solid #F2F2F2"
          :mr="[null, '20px']"
          :position="['fixed', 'relative']"
          :bottom="['0', '']"
          :width="['100%', 'inherit']"
          z-index="1"
        >
          <Navigation />
        </c-box>

        <c-box
          flex="1"
          :flex-basis="['100%', '70%']"
          :max-w="['100%', '70%']"
          :px="['20px', null]"
        >
          <router-view />
        </c-box>
      </c-flex>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue"
import Breadcrumb from "@/components/breadcrumb.vue"
import Navigation from "./navigation.vue"
import Footer from "@/components/Footer.vue"
import clientIdMixins from "@/utils/clientId-mixins"

export default {
  name: "NutritionistLayout",
  components: {
    Header,
    Breadcrumb,
    Navigation,
    Footer,
  },
  mixins: [clientIdMixins],
  computed: {
    isDataClient() {
      return this.$route.name === "nutri.detail-client"
    },
    isMealPlanNav() {
      return this.$route.matched
        .map((it) => it.meta.mealPlanNav)
        .some((it) => it)
    },
    isAdimeNote() {
      return this.$route.name.includes("adime-note")
    },
    isFollowupNotes() {
      return this.$route.name.includes("follow-up-notes")
    },
    breadcrumbs() {
      let breadcrumbs = [
        { href: "/", label: "Beranda" },
        {
          href: "#/management-client",
          label: "Manajemen Klien",
        },
      ]
      if (this.isDataClient) {
        breadcrumbs.push({
          label: "Diet Data Klien",
          href: `#/nutritionist/${this.clientId}/`,
          isCurrent: this.isDataClient,
        })
      }
      if (this.isMealPlanNav) {
        breadcrumbs.push({
          label: "Diet Data Klien",
          href: `#/nutritionist/${this.clientId}/`,
          isCurrent: this.isDataClient,
        })
        breadcrumbs.push({
          href: `#/nutritionist/${this.clientId}/meal-plan/`,
          label: "Meal Planner",
          isCurrent: this.isMealPlanNav,
        })
      }
      if (this.isAdimeNote) {
        breadcrumbs.push({
          href: `#/nutritionist/${this.clientId}/adime-note/`,
          label: "ADIME Note",
          isCurrent: this.isAdimeNote,
        })
      }
      if (this.isFollowupNotes) {
        breadcrumbs.push({
          href: `#/nutritionist/${this.clientId}/follow-up-notes/`,
          label: "Follow Up Notes",
          isCurrent: this.isFollowupNotes,
        })
      }
      return breadcrumbs
    },
    backTitle() {
      if (this.$route.name === "nutri.detail-client") {
        return "Data Diet Klien"
      }
      if (this.$route.name.includes('nutri.adime-note')) {
        return "ADIME Note"
      }
      if (this.$route.name.includes("nutri.follow-up-notes")) {
        return "Follow Up Notes"
      }
      if (this.$route.name.includes("nutri.progress-tracker")) {
        return "Progress & Tracker"
      }
      return "Klien"
    },
  },
}
</script>
